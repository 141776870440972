import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #F0FFFF;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .loader {
        border: 5px solid #f3f3f3;     /* cor do border */
        border-top: 5px solid blue; /* cor do border quando em rotação */
        border-radius: 50%;
        width: 70px;
        height: 70px;
        animation: spin 1s linear infinite;
        text-align: center;

    }
  
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

`