import React, { useEffect, useState } from "react";
import apiAgendaNaMao from "../../services/api";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form"
import Avatar from '@mui/material/Avatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import moment from "moment";
import Loader from "../../components/Loader";
import util from "../../util";
import { v4 } from "uuid";
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from "./loading";

import {
    Container,
    ContainerHeader,
    ContainerItens,
} from './stylesAgendamento'


export function AgendamentosServices() {

    const { id } = useParams([])
    const [visao, setVisao] = useState("services")
    const [dataAtual, setDataAtual] = useState(moment())
    const [usuarios, setUsuarios] = useState([])
    const [services, setServices] = useState([])
    const [profissionais, setProfissionais] = useState([])
    const [serviceSelected, setServiceSelected] = useState([])
    const [profissalSelectedFilter, setProfissalSelectedFilter] = useState([])
    const [slotDataSelecionada, setSlotDataSelecionada] = useState([])
    const [dayPosicaoSelect, setDayPosicaoSelect] = useState()
    const [dataSelect, setDataSelect] = useState()
    const [profissionalSelected, setProfissionalSelected] = useState({})
    const [slotHours, setSlotHours] = useState([])

    const [hourSelected, setHourSelected] = useState()

    const [diasDisponiveisAgenda, setDiasDisponiveisAgenda] = useState([])
    const [buscarServices, setBuscarServices] = useState("")
    const [opcao, setOpcao] = useState("")
    const [loading, setLoading] = useState(false)

    const [colorProfissional, setColorProfissional] = useState("")

    const [carrinho, setCarrinho] = useState([{}])
    const [NewCarrinho, setNewCarrinho] = useState([{}])

    const [ListNotifications, setListNotifications] = useState([])
    const [atualizando, setAtualizando] = useState(true)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()


    useEffect(() => {

        async function loadRequisicoes() {

            const { data: usuarios } = await apiAgendaNaMao.get(`usuarios/${id}`)
            setUsuarios(usuarios)
            setAtualizando(false)
            const { data: service } = await apiAgendaNaMao.get('service')
            setServices(service)

            const { data: collaborators } = await apiAgendaNaMao.get('collaborator')
            setProfissionais(collaborators)

            const { data: listNotification } = await apiAgendaNaMao.get('/push/list_public_key')
            setListNotifications(listNotification)

        }
        loadRequisicoes()

    }, [id])

    async function loadDias(params) {
        let utcAtualUser = moment.tz.guess()
        setLoading(true)
        try {

            const { data: diasDisponiveis } = await apiAgendaNaMao.post('diasdisponiveis', {
                date: moment().tz(utcAtualUser).format('YYYY-MM-DD'),
                service_id: params,
                userId: id,
                utcUser: utcAtualUser
            })
            setDiasDisponiveisAgenda(diasDisponiveis.agenda)
            setLoading(false)
        } catch (err) {
            alert('Error loading available days')
        }
    }

    //Filtrando servicos por usuário id e campo de busca por nome
    const filterServicesUser = services.filter(i => i.UserId === id && i.active === 'Sim').filter((item) => {
        if (buscarServices === "") {
            return item
        } else if (item.name.toLowerCase().includes(buscarServices.toLocaleLowerCase())) {
            return item
        }
        return this
    })

    const filterProfissionalUser = profissionais.filter(i => i.UserId === id)

    const filterNotifications = ListNotifications.filter(item => item.CollaboratorsId === profissionalSelected.id)

    function voltarData() {
        const data = moment(dataAtual).subtract(1, 'month')
        setDataAtual(data)
    }

    function avancarData() {
        const data = moment(dataAtual).add(1, 'month')
        setDataAtual(data)
    }

    //Filtrando data disponiveis com data do calendario selecionada
    const filterDataMes = diasDisponiveisAgenda.filter((item) => {
        if (moment(Object.keys(item)[0]).format('MM') === moment(dataAtual).format('MM')) {
            return item
        }
        return this
    })

    const formatarDiasDisponiveis = filterDataMes.map((item, index) => ({
        ano: moment(Object.keys(item)[0]).format('DD-MM-YYYY'),
        mes: moment(Object.keys(item)[0]).format('MMMM'),
        dia: moment(Object.keys(item)[0]).format('ddd'),
        data: moment(Object.keys(item)[0]).format('DD'),
        posicao: index,
        array: item,
        id: Object.keys(item),
        lengthHorarios: Object.values(Object.values(item)[0])[0].flat().filter((i) => moment(Object.keys(item)[0]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? i >= moment().format('HH:mm') : i).length
    }))

    const submitSelectData = (index) => {

        const percorrerData = formatarDiasDisponiveis.map(i => i.array)

        const filterDataSelecionada = percorrerData.filter((date, posi) => {
            return posi === parseInt(index)
        })
        setSlotDataSelecionada(filterDataSelecionada)

        const dataSelect = filterDataSelecionada.map(item => Object.keys(item)).toString()
        setDataSelect(dataSelect)
        const formatData = parseInt(moment(dataSelect).format('d'))
        setDayPosicaoSelect(formatData)


        // Pegando infor dos profissionais de acordo com data selecionada pelo usuario //

        //Filtrando id do profissional selecionado 
        const filterProfissionalDataSelect = filterDataSelecionada.map(item => Object.values(item).map(key => Object.keys(key))[0]).flat()

        //Filtrando id selecionado dos profissionais cadastrado no espaco
        const filterProfissionaisCadastrados = filterProfissionalUser.filter(coll => filterProfissionalDataSelect.some(i => i === coll.id))
        setProfissalSelectedFilter(filterProfissionaisCadastrados)

    }

    const submitSelectProffisional = (item) => {

        //Pegando horarios dos servicos adicionado no carrinho
        const ListHoursCarrinho = carrinho.filter(i => i.dia === dataSelect && i.CollaboratorsId === item.id)

        //Convertendo tempo do servico para minutos
        const timeService = util.hourToMinutes(moment(serviceSelected.time).format('HH:mm'))

        const newArryTotalHorarios = []
        const newArryTotalHorarios2 = []

        const cortesArray = 100

        //For para pegar o horario escolhido e somar e subitrair o tempo de duracao e adicionalos dentro do array
        for (let i = 0; i < ListHoursCarrinho.length; i = i + cortesArray) {
            newArryTotalHorarios.push(ListHoursCarrinho.map(i => util.sliceMinutesSubtrair(i.data, moment(i.data).subtract(timeService, 'minutes'), usuarios.intervalo_agendamento)).flat().flat().slice(i, i + cortesArray))
            newArryTotalHorarios2.push(ListHoursCarrinho.map(i => util.sliceMinutes(i.data, moment(i.data).add(timeService, 'minutes'), usuarios.intervalo_agendamento)).flat().slice(i, i + cortesArray))
        }

        //Pegando array da subritacao dos horarios e deixando apenas um arry
        const newArryHorariosCarrinho = newArryTotalHorarios.reduce((list, sub) => list.concat(sub), [])

        //Pegando array da soma dos horarios e deixando apenas um arry
        const newArryHorariosCarrinho2 = newArryTotalHorarios2.reduce((list, sub) => list.concat(sub), [])

        //Unindo dois arrays, array 1 que tem subritracao dos horarios e arry 2 que somas os horarios 
        const newArryUnico = newArryHorariosCarrinho.concat(newArryHorariosCarrinho2)

        //Pegando todos os horarios disponiveis por profissional selecionado e dando flat para separar array
        const hoursDispProfissional = slotDataSelecionada.map(i => Object.values(i)[0][item.id]).flat()

        //Pegando os horarios do profissional e deixanso apenas um array.
        const newArryHorarios = hoursDispProfissional.reduce((list, sub) => list.concat(sub), [])

        // Pegando os horarios do profissional e tirando os horarios repetidos dos arrys de soma e subitracao dos horarios.
        const newHorariosFiltrados = newArryHorarios.filter(a => !newArryUnico.includes(a))

        if (dataSelect === moment().format('YYYY-MM-DD')) {
            const newHoursList = newHorariosFiltrados.filter((i) => i >= moment().format('HH:mm'))
            setSlotHours(newHoursList)
            return
        } else {
            setSlotHours(newHorariosFiltrados)
        }
    }

    //ordenando os horarios em ordem correta
    function formatYearMonthPad(dateStr) {
        return dateStr.split(',')
            .reverse()
            .map((part) => part.padStart(2, '0'))
            .join();
    }

    slotHours.sort(function (a, b) {
        const aa = formatYearMonthPad(a);
        const bb = formatYearMonthPad(b);
        return aa < bb ? -1 : (aa > bb ? 1 : 0);
    });

    const hourSelect = (item) => {
        const formatHoraString = item.toString()
        setHourSelected(formatHoraString)

        const createCarrinho = {
            id: v4(),
            ServicesId: serviceSelected.id,
            hora: formatHoraString,
            dia: dataSelect,
            CollaboratorsId: profissionalSelected.id,
            UserId: id,
            day: dayPosicaoSelect,
            nameCol: profissionalSelected.name,
            nameService: serviceSelected.name,
            priceService: serviceSelected.price,
            timeService: serviceSelected.time,
            data: moment(`${dataSelect}T${formatHoraString}`),
            subscription: filterNotifications,
        }
        setCarrinho([...carrinho, createCarrinho])
    }

    useEffect(() => {
        const newCarrinho = carrinho.map(i => i)
        newCarrinho.splice(0, 1)
        setNewCarrinho(newCarrinho)

    }, [carrinho])

    function deleteServiceCarrinho(item) {
        const NewCarrinhoAtt = NewCarrinho.filter(i => i.id != item)
        const AttCarrinho = carrinho.filter(i => i.id != item)
        setNewCarrinho(NewCarrinhoAtt)
        setCarrinho(AttCarrinho)
    }

    const handlePhone = (event) => {
        let input = event.target
        input.value = phoneMask(input.value)
    }

    const phoneMask = (value) => {
        if (!value) return ""
        value = value.replace(/\D/g, '')
        value = value.replace(/(\d{2})(\d)/, "($1)$2")
        value = value.replace(/(\d)(\d{4})$/, "$1-$2")
        return value
    }

    const onSubmit = async (data) => {
        const formatTel = data.tel
        const tel = formatTel.replace("(", "").replace(")", "").replace("-", "")

        const formatCarrinho = NewCarrinho.map(i => ({
            name: data.name,
            tel: tel,
            ServicesId: i.ServicesId,
            CollaboratorsId: i.CollaboratorsId,
            date: new Date(`${i.dia}T${i.hora}`),
            days: i.day,
            UserId: i.UserId,
            hora: hourSelected,
            tipo_pagamento: 'Não escolhido',
            subscription: i.subscription,
            valor_total: i.priceService
        }))


        try {
            for (let i = 0; i < formatCarrinho.length; i++) {
                await toast.promise(
                    apiAgendaNaMao.post('agendamento', formatCarrinho[i]), {
                    pending: 'Verificando seus dados',
                    success: 'Agendamento realizado com sucesso!',
                },)
            }

            swal({
                text: `Agendamento realizado com Sucesso! \n  
                Colaborador: ${NewCarrinho.map(i => i.nameCol)} \n 
                Dia: ${NewCarrinho.map(i => moment(i.dia).format('DD'))} \n 
                Mês: ${NewCarrinho.map(i => moment(i.dia).format('MMM'))} \n 
                Hora: ${NewCarrinho.map(i => i.hora)}`,

                buttons: {
                    text: "Voltar"
                }

            }).then(() => {
                window.location.reload()
                window.location.href = `http://agendanamao.com/agenda/${id}`
            })

            try {
                await apiAgendaNaMao.post('/push/send', {
                    CollaboratorsId: profissionalSelected,
                    subscription: filterNotifications,
                    nameCliente: data.name,
                    dataAgendamento: `${moment(dataSelect).format('DD/MM/YYYY')}`,
                    hora: `${hourSelected}`,
                })
                return

            } catch (err) { }
        } catch (err) {
            const response = (err.response.data.error)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    }

    if (usuarios.status === "Desativado") {

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <h2>Conta desativada</h2>
                <p>Para mais informações, <br /> entre em contato com o suporte</p>
            </div>
        )
    }

    return (
        <Container>
            {atualizando === true ?
                <div className="divLoading">
                    <Loading />
                </div>
                :
                <>
                    <ContainerHeader style={{ backgroundColor: `${usuarios.cor_header ? usuarios.cor_header : "#0000CD"}` }}>
                        <div className="divAvatar">
                            <Avatar className="avatar" alt="foto perfil" src={usuarios.path} />
                            <h2>{usuarios.estabelecimento}</h2>
                        </div>
                    </ContainerHeader>

                    <ContainerItens >
                        {visao === "services" && (
                            <>
                                <div className="DivbuscarService">
                                    <input onChange={(item) => setBuscarServices(item.target.value)} placeholder="Buscar Serviço" />
                                </div>

                                <div className="listTelas">

                                    <div className="divOpcaoServicePacote">

                                        <p>{opcao === "Services" ? 'Selecione um pacote' : 'Selecione um serviço'}</p>

                                        {opcao === "Services" ? <div className="divButtonOpcao">
                                            <button onClick={() => setOpcao('Pacotes')} style={{ background: 'white', color: 'black' }}>Serviços</button>
                                        </div> : <div className="divButtonOpcao">
                                            <button onClick={() => setOpcao('Services')} style={{ background: 'green', color: 'white', fontWeight: 'bold' }}>Pacotes</button>
                                        </div>}

                                    </div>

                                    {filterServicesUser && filterServicesUser.map(i => (
                                        <div onClick={() => {
                                            setServiceSelected(i)
                                            setVisao("dias")
                                            loadDias(i.id)
                                        }} key={i.id} className="divSerive">

                                            <div>

                                            </div>

                                            <div className="divServiceInfor">
                                                <p id="nameSercice">{i.name}</p>
                                                <p id="timeService"><AccessTimeIcon style={{ width: '20px', marginRight: '4px', height: '20px', color: 'orange', background: 'transparent' }} /> {moment(i.time).format('HH')}h {moment(i.time).format('mm')}min</p>
                                                <p id="price"><AttachMoneyIcon style={{ width: '20px', height: '20px', color: 'green', background: 'transparent' }} /> R${i.price}</p>
                                            </div>

                                            <div id="divIconsSeta">
                                                <KeyboardArrowRightIcon style={{ color: 'blue', marginRight: '10px' }} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {visao === "dias" && (
                            <>
                                <div style={{ marginTop: '80px' }} className="listTelas">

                                    <div className="divOpcaoServicePacote">
                                        <p style={{ color: 'black', fontWeight: 'bold' }}>Selecione uma Data</p>
                                        <p onClick={() => {
                                            setVisao("services")
                                            setDiasDisponiveisAgenda([])
                                        }} style={{ display: 'flex', alignItems: 'center', color: 'red', fontWeight: 'bold' }}><ArrowBackIosNewIcon /> Voltar </p>
                                    </div>

                                </div>

                                <div >

                                    <div className="CalendarioTabela">

                                        <div className="divMesButtons" >
                                            <button onClick={voltarData} className="voltarDate"><ArrowBackIosNewIcon /></button>
                                            <h3>{moment(dataAtual).format('MMMM/YY').toUpperCase()}</h3>
                                            <button onClick={avancarData} className="newDate" ><ArrowForwardIosIcon /></button>
                                        </div>

                                        <div className="divCalendario">

                                            {formatarDiasDisponiveis.length === 0 && !loading && (

                                                <div style={{ width: '80vw', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                                    <p>Nenhuma data disponível para o mës selecionado</p>

                                                </div>

                                            )}
                                            {formatarDiasDisponiveis && formatarDiasDisponiveis.map(i => (
                                                <div key={i.id} className="caixaData">
                                                    <button value={i.posicao}
                                                        onClick={() => {
                                                            submitSelectData(i.posicao)
                                                            setVisao("profissionais")
                                                        }} > {i.dia}/{i.data} <div className="barraAgendamento"> <div style={i.lengthHorarios >= 15 ? { width: '100%', backgroundColor: '	#32CD32' } : i.lengthHorarios >= 5 ? { width: '60%', backgroundColor: '	#FFFF00' } : i.lengthHorarios >= 1 ? { width: '30%', backgroundColor: 'red' } : { width: '0%' }}></div></div></button>
                                                </div>
                                            ))}

                                        </div>
                                        {loading === true ? <Loader /> : ''}
                                    </div>
                                </div>
                            </>
                        )}

                        {visao === "profissionais" && (
                            <>
                                <div style={{ marginTop: '80px' }} className="listTelas">

                                    <div className="divOpcaoServicePacote">
                                        <p style={{ color: 'black', fontWeight: 'bold' }}>Selecione um profissional</p>
                                        <p onClick={() => {
                                            setVisao("dias")
                                            setProfissionalSelected([])
                                            setColorProfissional("")
                                        }} style={{ display: 'flex', alignItems: 'center', color: 'red', fontWeight: 'bold' }}><ArrowBackIosNewIcon /> Voltar </p>
                                    </div>

                                </div>

                                <div className="divProfissional">

                                    {profissalSelectedFilter && profissalSelectedFilter.map(i => (
                                        <button style={profissionalSelected.id === i.id ? { backgroundColor: colorProfissional, borderRadius: '4px' } : { backgroundColor: 'transparent' }} onClick={() => {
                                            submitSelectProffisional(i)
                                            setProfissionalSelected(i)
                                            setColorProfissional("#DCDCDC")
                                        }} className="buttonAvatarProfissional" key={i.id}>
                                            <Avatar style={{ width: '80px', height: '80px' }} alt="Foto perfil" src={i.path} />
                                            <p>{i.name}</p>
                                        </button>
                                    ))}
                                </div>


                                {profissionalSelected.name && (
                                    <>
                                        <div style={{ marginTop: '40px' }} className="listTelas">
                                            <div className="divOpcaoServicePacote">
                                                <p style={{ color: 'black', fontWeight: 'bold' }}>Selecione um horário</p>
                                            </div>
                                        </div>

                                        <div className="divHours">
                                            {slotHours && slotHours.map(i => (
                                                <div key={v4()} className="divListHours">
                                                    <button onClick={() => { hourSelect(i); setVisao("resumo") }} value={i}>{i}</button>
                                                </div>
                                            ))}
                                        </div>

                                        {slotHours.length === 0 && (
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '80%', margin: '10px', flexDirection: 'column' }}>
                                                <p style={{ color: 'red', fontSize: '15px' }}>Nenhum horário disponível para o profissinal {profissionalSelected.name}.</p>
                                                <p style={{ color: 'black', fontSize: '14px' }}>OBS: Escolha outro profissioal ou outra data.</p>
                                            </div>
                                        )}
                                    </>
                                )}

                            </>
                        )}

                        {visao === 'resumo' && (
                            <>
                                <div className="descricaoResumo">
                                    <hr></hr>
                                    <p>Resumo</p>
                                    <hr></hr>
                                </div>

                                <div className="divTextResumo">
                                    {NewCarrinho && NewCarrinho.map(i => (
                                        <div key={i.id} id="divComanda">

                                            <div id="divMesDiaHora">
                                                <h2>{moment(i.dia).format('MMM')}</h2>
                                                <h4>{moment(i.dia).format('DD')}</h4>
                                                <h5>{i.hora}</h5>
                                            </div>

                                            <div id="divServiceNamePrice">
                                                <p>{i.nameCol}</p>
                                                <p>{i.nameService}</p>
                                                <p>R${i.priceService}</p>
                                            </div>
                                            <button onClick={() => deleteServiceCarrinho(i.id)} ><DeleteIcon style={{ color: 'red' }} /></button>
                                        </div>
                                    ))}
                                </div>

                                <div className="divMaisService">
                                    <button onClick={() => {
                                        setVisao('services')
                                        setProfissionalSelected([])
                                        setDiasDisponiveisAgenda([])
                                    }}>Adicionar mais Serviço</button>
                                </div>

                                <div className="divConfirmeAgendamento">
                                    <h5>Confirme seu Agendamento</h5><br />
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <label>Seu nome</label>
                                        <input {...register("name", { required: true })} className="name" placeholder="Digite seu nome" />
                                        {errors.name && <span>Campo Obrigatório</span>}

                                        <label>Telefone</label>
                                        <input maxLength={15} type="tel" onKeyUp={(event) => handlePhone(event)} {...register("tel", { required: true })} className="tel" placeholder="DD XXXX-XXXX" />
                                        {errors.tel && <span>Campo Obrigatório</span>}

                                        <button className="ConfirmeAgendamento" type="submit">Confirmar Agendamento</button>
                                    </form>
                                </div>
                            </>
                        )}

                    </ContainerItens>
                </>
            }
        </Container>
    )
}