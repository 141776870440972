import {Container} from './styles'

function Loader(){
    return(
        <Container >
            <div className='loader'></div>
            <p style={{fontSize: '10px', color: 'black'}}>Carregando...</p>
        </Container>
    )
}

export default Loader