import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import apiAgendaNaMao from "../../services/api";
import { useParams } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Avatar from '@mui/material/Avatar';
import InstagramIcon from '@mui/icons-material/Instagram';
import Loading from "./loading";


import {
    Container,
    ContainerHeader,
    ContainerItens,

} from './styles'


export function AgendamentosRev1() {
    const { id } = useParams([])
    const [usuarios, setUsuarios] = useState([])
    const [atualizando, setAtualizando] = useState(true)

    useEffect(() => {
        setAtualizando(true)
        async function loadRequisicoes() {
            const { data: usuarios } = await apiAgendaNaMao.get(`usuarios/${id}`)
            setUsuarios(usuarios)
            setAtualizando(false)
        }
        loadRequisicoes()
    }, [])

    if (usuarios.status === "Desativado") {

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <h2>Conta desativada</h2>
                <p>Para mais informações, <br /> entre em contato com o suporte</p>
            </div>
        )
    }

    return (

        <Container>
            {atualizando === true ?
                <div className="divLoading">
                    <Loading />
                </div>
                :
                <>
                    <ContainerHeader style={{ backgroundColor: `${usuarios.cor_header ?usuarios.cor_header : "#0000CD"}` }} >
                        <div className="divAvatar">
                            <Avatar className="avatar" alt="foto perfil" src={usuarios.path} />
                            <h2>{usuarios.estabelecimento}</h2>
                            <p>{usuarios.cidade}</p>
                            <p>{usuarios.bairro} {usuarios.rua} {usuarios.numero}</p>
                        </div>
                    </ContainerHeader>

                    <ContainerItens className="divFuncoes">

                        <div className="divLinkBotoes">
                            <a rel="noreferrer" href={`http://agendanamao.com/agenda1/${id}`} className="item01">
                                <button style={{ backgroundColor: `${usuarios.cor_header ? usuarios.cor_header : "#0000CD"}` }} className="ButtonAgendar">Agendar Horário</button>
                            </a>

                            <a rel="noreferrer" href={`http://agendanamao.com/cliente/${id}`}>
                                <p>Área do cliente</p>
                            </a>
                        </div>

                        <div className="divRedes">

                            <a rel="noreferrer" target="_blank" href={`https://wa.me//55${usuarios.tel}?text=Olá,%20gostaria de mais informações.`} id="linkImgWhats" className="item02">
                                <button><WhatsAppIcon style={{ width: '30px', height: '33px', marginLeft: '5px', color: 'green' }} /> </button>
                            </a>
                            <a rel="noreferrer" target="_blank" href={usuarios.instagram}>
                                <button><InstagramIcon style={{ width: '30px', height: '33px', marginLeft: '5px', color: 'black' }} /> </button>
                            </a>
                            <a className="item02" rel="noreferrer" target="_blank" href={`http://maps.google.com/maps?saddr=street+adress&daddr=${usuarios.rua}${usuarios.numero}`}>
                                <button><LocationOnIcon style={{ width: '30px', height: '33px', marginLeft: '5px', color: 'red' }} /> </button>
                            </a>
                        </div>


                    </ContainerItens>
                </>
            }
        </Container>
    )


}

