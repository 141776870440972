import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form"
import apiAgendaNaMao from "../../../services/api";
import { useUser } from "../../../hooks/UserContext";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import moment from "moment-timezone";
import { toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import {
    Container,
    ContainerFilters,
    ContainerReceitaBruta,
    ContainerReceitaService
} from './styles'


export function Financeiro(){

    const {userData}=useUser({})
    const [AgendaFiltrada, setAgendaFiltrada] = useState([])
    const [Collaborators, setCollaborators] = useState([])
    const [Comissoes, setComissoes] = useState([])
    const [FilterCollaborators, setFilterCollaborators] = useState([])
    const [Despesas, setDespesas] = useState([])
    const [FilterDespesas, setFilterDespesas] = useState([])
    const [FilterComissoes, setFilterComissoes] = useState([])
    const [Agendamentos, setAgendamentos] = useState([])
    const [DataFimSelect, setDataFimSelect] = useState([])
    const [DataStartSelect, setDataStartSelect] = useState([])
    const [FilterAgenda, setFilterAgenda] = useState([])
    const [DataFimSelectSemAddDay, setDataFimSelectSemAddDay] = useState([])

  
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    useEffect(()=>{

        
        async function loadRecs(){
            const {data: collaborator} = await apiAgendaNaMao.get('collaborator')
            setCollaborators(collaborator)

            const {data: despesas} = await apiAgendaNaMao.get('despesas')
            setDespesas(despesas)

            const {data: agendamentos} = await apiAgendaNaMao.get('agendamento')
            setAgendamentos(agendamentos)

            const {data: comissoes} = await apiAgendaNaMao.get('comissao')
            setComissoes(comissoes)
        }


        loadRecs()
    },[])

    useEffect(()=>{

        const filterUserColl = Collaborators.filter(col => col.UserId === userData.id || col.UserId === userData.UserId)
        
        setFilterCollaborators(filterUserColl)

        const filterDespesas = Despesas.filter(desp => desp.UserId === userData.id || desp.UserId === userData.UserId)
        setFilterDespesas(filterDespesas)

        const filterAgenda = Agendamentos.filter(agen => agen.UserId === userData.id || agen.UserId === userData.UserId).filter(
            stat => stat.status === "Concluído").filter(dat => moment(dat.date).format('YYYY-MM-DD') >= DataStartSelect && moment(dat.date).format('YYYY-MM-DD') <= DataFimSelect)
        setFilterAgenda(filterAgenda)

        const filterComissoes = Comissoes.filter(i => i.UserId === userData.id || i.UserId === userData.UserId)
        setFilterComissoes(filterComissoes)

    },[Collaborators,userData.id,userData.UserId, Despesas, Agendamentos,DataFimSelect,DataStartSelect, Comissoes])

    const onSubmit = async (item) => {
        
        try{

            if(userData.collaborator){

                const {data} = await toast.promise(apiAgendaNaMao.post('filter',{
                    UserId: userData.UserId,
                    dateInicio: moment(item.dateInicial).format('YYYY-MM-DD'),
                    dateFim: moment(item.dateFinal).add(1, 'days').format('YYYY-MM-DD'),
                    CollaboratorsId: item.coll,
                    statusAtual: "Concluído"
                }),{
                    pending: 'Carregando Relatório',
                    success: `Relatório Carregado com sucesso!`,
                })
                

                setDataFimSelect(moment(item.dateFinal).add(1, 'days').format('YYYY-MM-DD'))
                setDataStartSelect(moment(item.dateInicial).format('YYYY-MM-DD'))

                setDataFimSelectSemAddDay(moment(item.dateFinal).format('YYYY-MM-DD'))
                setAgendaFiltrada(data)

            }else{

                const {data} = await toast.promise(apiAgendaNaMao.post('filter',{

                    UserId: userData.id,
                    dateInicio: moment(item.dateInicial).format('YYYY-MM-DD'),
                    dateFim: moment(item.dateFinal).add(1, 'days').format('YYYY-MM-DD'),
                    CollaboratorsId: item.coll,
                    statusAtual: "Concluído"
                }),{
                    pending: 'Carregando Relatório',
                    success: `Relatório Carregado com sucesso!`,
                })

                setDataFimSelect(moment(item.dateFinal).add(1, 'days').format('YYYY-MM-DD'))
                setDataStartSelect(moment(item.dateInicial).format('YYYY-MM-DD'))

                setDataFimSelectSemAddDay(moment(item.dateFinal).format('YYYY-MM-DD'))
                setAgendaFiltrada(data)
            }

        }catch(err){

            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } 
    }

    const valuesReceitaBruta = FilterAgenda.map(value => value.valor_total? value.valor_total : value.service.price)
   

    let receitaBruta = 0

    for(let i = 0; i < valuesReceitaBruta.length; i++){
        receitaBruta += valuesReceitaBruta[i]
    }

    

    //Calculo para somar todas despesas avista no periodo selecionado 
    const despesaBrutaAvista = []
    const filterAvista = FilterDespesas.filter(i => i.tipo_pagamento === "Á vista" && moment(i.data_pagamento).format('YYYY-MM-DD') >= DataStartSelect && moment(i.data_pagamento).format('YYYY-MM-DD') <= DataFimSelectSemAddDay)
    filterAvista.forEach((i)=>{
   
        const despesaAvista = despesaBrutaAvista[i.descricao] || 0
        
        despesaBrutaAvista[i.descricao] = despesaAvista + i.valor_total
    })

    const FormatDespesas = Object.keys(despesaBrutaAvista).map((key, index)=>{
        return {valorDespesaAvista: despesaBrutaAvista[key]}
    })

    let SomaTotalDespesaAvista = 0
    const SomarDespesaAvista = FormatDespesas.map(i => i.valorDespesaAvista)

    for(let i= 0; i < SomarDespesaAvista.length; i++){
        SomaTotalDespesaAvista += SomarDespesaAvista[i]
    }
    // Fim ______________________________



    //Calculando para somar todas as despesa no cartao
   const filterDespesaCartao = FilterDespesas.filter(i => i.tipo_pagamento === "Cartão" && moment(i.data_vencimento).add((i.quantidade_parcela -1),'months').format('YYYY-MM-DD') >= DataStartSelect && moment(i.data_vencimento).format('YYYY-MM-DD') <= DataFimSelectSemAddDay)
   let despesaCartaoObjeto = []
    filterDespesaCartao.forEach((i)=>{
        const despesaCartao = despesaCartaoObjeto[i.descricao] || 0

        despesaCartaoObjeto[i.descricao] = despesaCartao + i.valor_total
    })

    const formatDespesaCartao = Object.keys(despesaCartaoObjeto).map((key,index)=>{
        return {valorDespesaCartao: despesaCartaoObjeto[key]}
    })
    const valoresDespesaCartao = formatDespesaCartao.map(i => i.valorDespesaCartao)

    let somaTotalDespesaCartao = 0
    for(let i = 0; i < valoresDespesaCartao.length; i++){
        somaTotalDespesaCartao += valoresDespesaCartao[i]
    }

    let SomaDespesaAvistaCartao = SomaTotalDespesaAvista + somaTotalDespesaCartao
    //Fim ____



    //Pegando o preço dos serviços, fazendo um for para somar todos.
    const valuesReceita = AgendaFiltrada.map(value => value.valor_total?  value.valor_total : value.service.price)
    
    let receita =  0

    //formatando o valor para a moeda real
    let formatReal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

    //for para percorer os preços e somar.
    for(let i = 0; i < valuesReceita.length; i++){
        receita += valuesReceita[i]
    }


    
    
    /* é um array temporário para salvar o 
    valor de service como uma key deste array, além de manter um valor 
    acumulado de cada price da lista de agendamento */
    const ReceitaValor = []
    const ReceitaComissao = []

    //forEach para interar os itens
    AgendaFiltrada.forEach((elemt)=>{
        
        //Filtrando comissoes salvas pelo colaborador e servico com agendamentos concluídos
        //const comissoesFilterCollService =  FilterComissoes.filter(i => i.CollaboratorsId === elemt.CollaboratorsId && i.ServicesId === elemt.ServicesId).map(i => i.comissao? i.comissao/100 : '')
        FilterComissoes.filter(i => {

            const valorAnteriors = ReceitaComissao[i.ServicesId] || 0;
            ReceitaComissao[i.ServicesId] = valorAnteriors + (elemt.ServicesId === i.ServicesId ? i.service.price * (i.comissao? i.comissao / 100 : '') : '')

            
            return this
        })
        

             /* valor anterior receve o valor já salvo na ReceitaValor 
            de acordo com a Chave(Barba,Corte...etc), 
            se a chave não existir adotamos vamos 0 */
           const valorAnterior = ReceitaValor[elemt.service.name] || 0;
          
            /* adiciona uma chave ao array ReceitaValor com um valor inicial de 
            acordo com o elemt.service.price. se a chave já existir ele já tem um valor, 
            então faremos uma soma. */
            ReceitaValor[elemt.service.name] = valorAnterior + ( elemt.valor_total ? elemt.valor_total : elemt.service.price);
          
            //ReceitaValor[elemt.service.name] = valorAnterior + elemt.service.price;
        
            return this
    })

    
    /* a partir de agora é feito uma associação a chave do ReceitaValor 
    para um Objeto com os nomes das propriedades. */
    const novoLista = Object.keys(ReceitaValor).map((key,index)=>{
        
        const priceUnitario = AgendaFiltrada.filter(p => p.service.name === key).map(p => p.valor_total? p.valor_total : p.service.price )[0]
        
        return {Service: key, preco: ReceitaValor[key], precoUnitario: priceUnitario, id: index};
    })  
    //Criando arry com todos os nomes dos servicos
    const QtdeServico = AgendaFiltrada.map(i => i.service.name)
    //funcao para receber o arry com o nome dos servicos e passo o nome e ele conta a ocorrencia
    function calcularNumeroServicos(array, value) {
        var count = 0;
        array.forEach((v) => (v === value && count++));
        return count;
    }


    const novoListaComissao = Object.keys(ReceitaValor).map((key,index)=>{
      const co = FilterComissoes.filter(a => a.service.name === key).map(i => (i.comissao / 100))
      return {Service: key, preco: ReceitaValor[key], comissao: ReceitaValor[key] * co, id: index};
    })

   

    const arryDeComissaoArreceber = novoListaComissao.map(i => i.comissao)
    
    let comisaoAreceber = 0

    for(let i= 0; i < arryDeComissaoArreceber.length; i++){
        comisaoAreceber += arryDeComissaoArreceber[i]
    }


    const arryComissao = Object.keys(ReceitaComissao).map((key,index)=>{
        return parseFloat(ReceitaComissao[key]) 
    })
    
    let somatorioComissao = 0

    for(let i = 0; i < arryComissao.length; i++){
        somatorioComissao += arryComissao[i]
    }
    


    return(
        <Container>
            
            <h1 style={{color: 'white'}}>Relatório Financeiro</h1>
            
            <ContainerFilters>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div id="divDate">
                        <p>Data Inicial:
                            <input  name="dateInicial" defaultValue={moment().format('YYYY-MM-DD')} {...register("dateInicial", {required: true})} type="date"/>
                            {errors.dateInicial && <span>Campo Obrigatório</span>}
                        </p>

                        <p>Data Final: 
                            <input   name="dateFinal" defaultValue={moment().format('YYYY-MM-DD')} {...register("dateFinal", {required: true})} type="date"/>
                            {errors.dateFinal && <span>Campo Obrigatório</span>}
                        </p>

                        <p>Colaborador:
                            <select  name="coll" {...register("coll", {required: true})}>
                                <option value="" hidden >Selecione</option>
                                {FilterCollaborators && FilterCollaborators.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))} 
                            </select>
                            {errors.coll && <span>Campo Obrigatório</span>}
                        </p>
                    </div>
                    <button type="submit" >Filtrar</button>
                </form>
            </ContainerFilters>
            <ContainerReceitaBruta>

                <div>

                    <div id="divTopoReceitaBruta">
                        <p>Receita Bruta </p><PointOfSaleIcon  style={{marginRight: '3px', color: 'white'}}/>
                    </div>

                    <div id="divValueReceita">
                        <div id="divDentroValor">
                            <p>{formatReal.format(receitaBruta)}</p>
                        </div>
                    </div>

                </div>

                <div>
                    <div id="divTopoReceitaDespesas">
                        <p>Despesas Bruta</p><CreditCardIcon  style={{marginRight: '3px', color: 'white'}}/>
                    </div>

                    <div id="divValueDespesa">
                        <div id="divDentroValor">
                            <p style={{color: 'red'}}>{formatReal.format(SomaDespesaAvistaCartao)}</p>
                        </div>
                    </div>

                </div>

                 <div>
                    <div id="divTopoReceitaDespesas-despesa">
                        <p>Receita - Despesas</p><PointOfSaleIcon  style={{marginRight: '3px', color: 'white'}}/>
                    </div>

                    <div id="divValueReceita">
                        <div id="divDentroValor">
                            <p>{formatReal.format( (receitaBruta) - (SomaDespesaAvistaCartao) )}</p>
                        </div>
                    </div>
                </div> 

            </ContainerReceitaBruta>

            <ContainerReceitaService>

                <div id="divExtratoColl">
                    <h4>Extrato por Colaborador Selecionado</h4>
                </div>                                
                
                <div id="divItensCollReceita">
                    
                    <div id="divPhotoColl">
                        <Stack style={{display: 'flex', alignItems: 'center', marginLeft: '4px'}} direction="row" spacing={1}>
                            <Avatar alt="Remy Sharp" src={AgendaFiltrada.length > 0 ? AgendaFiltrada[0].Collaborator.path : ''} />
                            <p>{AgendaFiltrada.length > 0 ? AgendaFiltrada[0].Collaborator.name : ''}</p>
                        </Stack>
                    </div>

                    <div id="divFaturamentoTotal">
                        <p>Faturamento Total:</p>
                        <p>{formatReal.format(receita)}</p>
                    </div>

                    <div id="divFaturamentoTotalComissao">
                        <p>Comissão a receber:</p>
                        <p>{formatReal.format(comisaoAreceber)}</p>
                    </div>

                    <hr></hr>

                    <div id="divExtratoServicoPrecoComissao">
                        <p>Extrado: Serviço | preço do serviço | comissão</p>
                    </div>

                    <div id="divColumResumo">

                        <div id="divColum1">
                            {novoLista && novoLista.map(i => (
                                <div id="divNameSerico" key={i.id}>
                                    <p>{i.Service}<sup>{calcularNumeroServicos(QtdeServico, i.Service)}x</sup></p>
                                    
                                </div>
                            ))}
                        </div>
                        

                        <div id="divColum1">
                            {novoLista && novoLista.map(r => (
                                <div id="divValorReal" key={r.id}>
                                    <p>{formatReal.format(r.preco)}</p>
                                </div>
                            ))}
                        </div>

                         <div id="divColum1">
                            {novoListaComissao && novoListaComissao.map(i => (
                                <div id="divValorComissao" key={i.id}>
                                    <p>{formatReal.format(i.comissao)}</p>
                                </div>
                            ))}
                        </div> 

                    </div>
        
                </div> 
            </ContainerReceitaService>
        </Container>
    )
}

