import React, {useState} from "react";
import { useForm } from "react-hook-form"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useUser} from '../../hooks/UserContext'
import apiAgendaNaMao from "../../services/api";
import { toast } from 'react-toastify';
import EditNoteIcon from '@mui/icons-material/EditNote';
import listTipoPagamento from "./listTipoPagamento"

import {
    Container,ContainerItens

} from "./styles"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export function ModalEditeComandas(comandas){

  const [open, setOpen] = React.useState(false);
  const [screm, setScrem] = useState("Á vista")

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {userData} = useUser({})

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  function SelectedPagamento(e){
    setScrem(e)
   
  }

  const onSubmit = async (data) => {
    const desconto = data.desconto? Number(data.desconto.replace(",", ".",Number)) : 0
    const adicional = data.adicional? Number(data.adicional.replace(",", ".",Number)) : 0

    
    const formatData = {
      name: data.name,
      tel: data.tel,
      tipo_pagamento: data.tipo_pagamento,
      desconto: data.desconto? Number(data.desconto) : 0,
      adicional: data.adicional? Number(data.adicional) : 0,
      total: (data.desconto? Number(data.valor_total.replace(",", "."), Number) -  desconto : Number(data.valor_total.replace(",", "."), Number) + adicional) 
    }

    try{
      
      if(userData.collaborator){
         await toast.promise(apiAgendaNaMao.put(`/updateAgendamento/${comandas.props.id}`,{
            name: data.name,
            tel: data.tel,
            tipo_pagamento: data.tipo_pagamento,
            valor_total: formatData.total,
            observacao: data.observacao
        }),{
          pending: 'Verificando seus dados',
          success: 'Agendamento realizado com sucesso!',
        })
        return handleClose(false), window.location.reload()
      }

      await toast.promise(apiAgendaNaMao.put(`/updateAgendamento/${comandas.props.id}`,{
        name: data.name,
        tel: data.tel,
        tipo_pagamento: data.tipo_pagamento,
        valor_total: formatData.total,
        observacao: data.observacao
      }),{
        pending: 'Verificando seus dados',
        success: 'Agendamento realizado com sucesso!',
      }) 
      return handleClose(false), window.location.reload()
      
    }catch(err){
      const response = (err.response.data.error)
      toast.error( response,{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
    }
  }
  

  return(
      <Container>
        <Button onClick={handleOpen}><EditNoteIcon  style={{ color: 'black', marginBottom: '5px'}}/> </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Editar comanda.
              </Typography>

              <ContainerItens>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>   
                  <p>Nome:</p>
                  <input {...register("name")} defaultValue={comandas.props.name} type="text"/>
                  
                  <p>Tel:</p>
                  <input {...register("tel")} defaultValue={comandas.props.tel} type="tel"/>
                  
                  <p>Forma pagamento:</p>
                  <select defaultValue={comandas.props.tipo_pagamento} {...register("tipo_pagamento")} onChange={e => SelectedPagamento(e.target.value)}>
                    {listTipoPagamento.map(i => (
                      <option defaultValue={i.value.includes(comandas.props.tipo_pagamento)}  key={i.id} value={i.value}>{i.label}</option>
                    ))}
                  </select>

                  {screm === "Cartão" && 
                    <>
                      <p>Valor adicional</p>
                      <input defaultValue={0} {...register("adicional")} type="number" />
                      <p>Total:</p>
                      <input disabled style={{backgroundColor: '#A9A9A9'}} {...register("valor_total")} defaultValue={ comandas.props.valor_total? comandas.props.valor_total : comandas.props.service.price}  type="number"/>
                  </>}

                  {screm === "Á vista + Desconto" && 
                    <>
                      <p>Desconto</p>
                      <input {...register("desconto")} type="number" />
                      <p>Total:</p>
                      <input disabled style={{backgroundColor: '#A9A9A9'}} {...register("valor_total")} defaultValue={ comandas.props.valor_total? comandas.props.valor_total : comandas.props.service.price}  type="number"/>
                  </>}

                  {screm === "Pix" && 
                    <>
                    <p>Total:</p>
                    <input {...register("valor_total")} defaultValue={ comandas.props.valor_total? comandas.props.valor_total : comandas.props.service.price}  type="number"/>
                  
                  </>}

                  {screm === "Pix + Desconto" && 
                    <>
                    <p>Desconto</p>
                    <input defaultValue={0} {...register("desconto")} type="number" />
                    <p>Total:</p>
                    <input disabled style={{backgroundColor: '#A9A9A9'}} {...register("valor_total")} defaultValue={ comandas.props.valor_total? comandas.props.valor_total : comandas.props.service.price}  type="number"/>
                  
                  </>}

                  {screm === "Á vista" && 
                    <>
                    <p>Total:</p>
                    <input {...register("valor_total")} defaultValue={ comandas.props.valor_total? comandas.props.valor_total : comandas.props.service.price}  type="number"/>
                
                  </>}

                  <p>Observação:</p>
                  <textarea defaultValue={comandas.props.observacao} {...register("observacao")}></textarea>

                  <button type="submit">Atualizar</button>
                </form>
              </ContainerItens> 
            </Box>
        </Modal>
        
      </Container>
    )
}