import React, { useEffect, useState } from "react";
import apiAgendaNaMao from "../../services/api";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form"
import Avatar from '@mui/material/Avatar';
import moment from "moment";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Loading from "./loading";
import {
    Container,
    ContainerHeader,
    ContainerItensTelaCliente,
} from './stylesAgendamento'

export function AreaCliente() {

    const { id } = useParams([])
    const [usuarios, setUsuarios] = useState([])
    const [listAgendamentos, setListAgendamentos] = useState([])
    const [atualizando, setAtualizando] = useState(true)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        async function loadRequisicoes() {
            const { data: usuarios } = await apiAgendaNaMao.get(`usuarios/${id}`)
            setUsuarios(usuarios)
            setAtualizando(false)
        }
        loadRequisicoes()
    }, [id])

    const SubmitBuscarAgendamentos = async (infor) => {

        try {
            const { data } = await apiAgendaNaMao.get(`/agendamento`)

            const filterAgendamentos = data.filter((item) => item.tel === infor.tel && item.UserId === id)
            setListAgendamentos(filterAgendamentos)

        } catch (err) {
            alert('Error ao carregar os Agendamentos')
        }
    }

    //Excluir comanda
    async function deleteAgendamento(item) {

        if (window.confirm('Tem certeza que deseja excluir o Agendamento?')) {
            await apiAgendaNaMao.delete(`/agendamento/${item.id}`)
            window.location.reload()
        }

    }

    return (
        <Container>
            {atualizando === true ?
                <div className="divLoading">
                    <Loading />
                </div>
                :
                <>
                    <ContainerHeader style={{ backgroundColor: `${usuarios.cor_header ? usuarios.cor_header : "#0000CD"}` }}>
                        <div className="divAvatar">
                            <Avatar className="avatar" alt="foto perfil" src={usuarios.path} />
                            <h2>{usuarios.estabelecimento}</h2>
                        </div>
                    </ContainerHeader>

                    <ContainerItensTelaCliente>
                        <div className="divAgendamentosClientes">
                            <h3>Buscar Agendamentos</h3>
                            <div className="divFilterAgendamentos">
                                <p>Por favor, Digite o número do celular(WhatsApp) usado para
                                    agendar o serviço.
                                </p>

                                <form onSubmit={handleSubmit(SubmitBuscarAgendamentos)} >
                                    <input  {...register("tel", { required: true })} name="tel" type="tel" placeholder="Tel" />
                                    <div className="divButtons">
                                        <button type="submit">Enviar</button>
                                    </div>
                                </form>

                            </div>
                        </div>

                        <div className="listAgendamentos">
                            {listAgendamentos && listAgendamentos.map(i => (

                                <div className="divListAgendamento" key={i.id}>
                                    <p> Nome: {i.name}</p>
                                    <p> Data: {moment(i.date).tz("America/Sao_Paulo").format('DD-MM-YYYY')}</p>
                                    <p> Status: {i.status}</p>
                                    <p style={{ display: 'flex', alignItems: 'center' }}>Excluir Agendamento
                                        <button disabled={i.status === "Concluído"} onClick={() => deleteAgendamento(i)} ><DeleteForeverIcon /></button>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </ContainerItensTelaCliente>
                </>}
        </Container>
    )
}