import styled from "styled-components";


export const ContainerPrincipal = styled.div`
    background-color: blue;

    .icone-whats-flutuante{
        position: fixed;
        bottom: 10px;
        right: 10px;
        @media(max-width: 820px){
            bottom: 20px;
            right: 20px;
        }
    }
`

export const SlidTitle = styled.main`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;

    .titleSlide{
        width: 60%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        font-size: 14px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-weight: bold;
        margin: 20px;
    }
`

export const DisButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 15px;
    button{
        width: 260px;
        height: 45px;
        border: none;
        font-size: 15px;
        border-radius: 3px;
        color: white;
        padding: 7px;
        background: rgb(106,98,232);
        background: linear-gradient(357deg, rgba(106,98,232,1) 0%, rgba(5,5,185,1) 100%, rgba(0,212,255,1) 100%);
        cursor: pointer;
    }

`

export const DivSlid = styled.main`
    display: flex;
    align-items: center;
    justify-content: center;
    
    .slide-item{
        width: 100%;
        height: 530px;
        object-fit: cover;
       
        @media(max-width: 1070px){
            object-fit: cover;
            height: 330px;
        }

        @media(max-width: 820px){
            object-fit: cover;
            height: 330px;
        }

        @media(max-width: 740px){
            object-fit: cover;
            height: 230px;
        }
    }

    .slide-itemMenor{
        width: 100%;
        height: 350px;
        object-fit: cover;

        @media(max-width: 820px){
            object-fit: cover;
            height: 350px;
        }
    }
`


export const Header = styled.header`
    user-select: none;
    position: fixed;
    top: 0;
    width: 100vw;
    padding: 0.5rem 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    transition: top 0.3s ease-in-out;
    background-color: #FFFFFF; 
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 

    @media(max-width: 820px){
        user-select: none;
        position: fixed;
        top: 0;
        width: 100vw;
        padding: 0.5rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 99;
        transition: top 0.3s ease-in-out;
        background-color: #FFFFFF; 
        backdrop-filter: blur(10px); 
        -webkit-backdrop-filter: blur(10px); 
    }

    .menu{
        display: flex;
        flex-direction: row;    
       

        .nav-menu{
            display: flex;
            gap: 10px;

            @media(max-width: 820px){
                gap: 5px;
            }

           a{

            text-decoration: none;
            font-weight: bold;

                @media(max-width: 820px){
                    text-decoration: none;
                    font-size: 10px;
                    gap: 5px;
                }
           } 
        }
    }

    .login{
        display: flex;
        align-items: center;
        margin-right: 10px;
        a{
            text-decoration: none;
            font-weight: bold;

            @media(max-width: 820px){
                font-size: 10px;
            }
        } 
    }

`

export const Main = styled.main`
    width: 100%;
    height: 60vh;
    background: rgb(106,98,232);
    background: linear-gradient(357deg, rgba(106,98,232,1) 0%, rgba(5,5,185,1) 100%, rgba(0,212,255,1) 100%);
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0rem 1rem;
    z-index: 1;
    user-select: none;
    flex-direction: column;
    .container-inicio{
        transform: translateX(-700px);
        opacity: 0;
    }

    .conteudo-inicio{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 0.1rem;
        margin-top: 40px;

        .titulo-descricao{
            font-size: 22px;
        }

        .titulo-baixeAgora{
            font-size: 15px;
            margin-top: 30px;
        }
    }

    .titulo-inicio {
        font-size: clamp(1rem, 1.0vw + .8rem, 3rem);
        font-family: var(--font-grande);
        font-weight: normal;
        color: white;
        margin-top: 10px;
        .titulo-agendanamao{
            @media(max-width: 820px){
               font-size: 32px;
            }   
        }
    }   

    .destaque-inicio {
        font-weight: lighter;
        color: white;
    }

    .paragrafo-inicio{
        font-size: clamp(1.2rem, 1.5vw + 1rem, 2rem);
        max-width: 900px;
        color: var(--color-gray2);
        font-family: var(--font-pequena);
        color: white;

        .titulo-descricao{
            @media(max-width: 820px){
                font-size: 15px;
            }
        }
    }

    .div-baixarApp{
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
`

export const ImgLogoHeader = styled.img`
    width: 100px;

    @media(max-width: 820px){
        width: 70px;
    }

`

export const ImgAppStore = styled.img`
    width: 180px;
    height: 52px;

    @media(max-width: 820px){
        width: 145px;
        height: 42px;
    }
`
export const ImgPlayStore = styled.img`
    width: 180px;
    @media(max-width: 820px){
        width: 145px;
    }

`

export const ImgIconeWhats = styled.img`
    width: 70px;
    @media(max-width: 820px){
        width: 50px;
    }
`

export const ImgFuncionalidade = styled.img`
    width: 180px;

    @media(max-width: 820px){
        width: 140px;
    }

`

export const ImgFuncionalidadePrecificacao = styled.img`
    width: 185px;

    @media(max-width: 820px){
        width: 145px;
    }

`

export const MainSobre = styled.main`
    width: 100vw;
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
   
    @media(max-width: 820px){
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .div-titulo-inicial-MainSobre{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        .titulo-principal-mainSobre{
            font-size: 33px;
            text-align: center;
            color: black;
            font-weight: bold;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

            @media(max-width: 820px){
                font-size: 23px;
                text-align: center;
                color: black;
                font-weight: bold;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
        }

        .titulo-secundario-mainSobre{
            font-size: 16px;
            margin-top: 10px;
            text-align: center;
            color: black;

            @media(max-width: 820px){
                font-size: 14px;
                margin-top: 10px;
                text-align: center;
                color: black;
            }
        }


    }

    .div-carrosel-funcionalidades{
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-top: 20px;
        justify-content: center;
        
        @media(max-width: 820px){
            display: grid;
            grid-template-columns: 1fr 1fr;
            flex-direction: column;
            gap: 5px;
            margin-top: 20px;
            justify-content: center;
        }
        #itens{
            display: flex;
            flex-direction: column;
            width: 180px;
            align-items: center;

            @media(max-width: 820px){
                width: 170px;
            }

            .descricao-funcionalidades{
                font-size: 12px;
                margin-top: 5px;
            }

            .titulo-Tela{
                font-weight: bold;

                @media(max-width: 820px){
                    font-size: 12px;
                }
            }
        }
        
    }

`

export const MainPlanos = styled.main`
    width: 100%;
    height: 100vh;
    align-items: center;
    background: rgb(106,98,232);
    background: linear-gradient(184deg, rgba(106,98,232,1) 0%, rgba(5,5,185,1) 100%, rgba(0,212,255,1) 100%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    @media(max-width: 820px){
        width: 100%;
        height: 100%;
        align-items: center;
        background: rgb(106,98,232);
        background: linear-gradient(184deg, rgba(106,98,232,1) 0%, rgba(5,5,185,1) 100%, rgba(0,212,255,1) 100%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 10px;
    }

    .button-mes-ano{
        display: flex;
        gap: 20px;
        margin-top: 30px;
        align-items: center;
        .mensal{
            width: 100px;
            height: 30px;
            cursor: pointer;
            border-radius: 3px;
            border: none;
        }

        .anual{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 130px;
            height: 30px;
            cursor: pointer;
            border-radius: 3px;
            border: none;

            .anual-off{
                margin-left: 5px;
                background-color: green;
                padding: 5px;
                border-radius: 5px;
                font-size: 12px;
            }
        }
    }

    .planos{
        display: flex;
        gap: 20px;
        
        @media(max-width: 820px){
            display: flex;
            gap: 5px;
            flex-direction: column;
        }
        .anual-off{
                margin-left: 5px;
                background-color: green;
                padding: 5px;
                width: 80px;
                border-radius: 5px;
                font-size: 12px;
            }
    }

    .plano-preco{
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            color: white;

            @media(max-width: 820px){
               font-size: 22px;
               margin-top: 10px;
            }
        }

        .descricao-planos{
            color: white;
            margin-top: 8px;
            margin-left: 10px;

            @media(max-width: 820px){
               font-size: 14px;
            }
        }
    }

    .plano-basico{
        margin-top: 50px;
        background: rgb(175,173,208);
        background: linear-gradient(261deg, rgba(175,173,208,1) 0%, rgba(252,252,252,1) 100%, rgba(0,212,255,1) 100%);
        width: 280px;
        height: 420px;
        border-radius: 5px;
        padding: 20px;

        @media(max-width: 820px){
            margin-top: 30px;
            background: rgb(175,173,208);
            background: linear-gradient(261deg, rgba(175,173,208,1) 0%, rgba(252,252,252,1) 100%, rgba(0,212,255,1) 100%);
            width: 280px;
            height: 420px;
            border-radius: 5px;
            padding: 20px;
        }

        hr{
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .preco-mes{
            color: green;
            font-weight: bold;
            margin-top: 10px;
        }

        .div-descricao-plano{
            display: flex;
            flex-direction: column;
            font-size: 13px;
        }

        .preco-mes-reducao{
            color: red;
        }

        .economia{
            font-size: 11px;
            margin-top: 10px;
        }

        .div-button-adesao{
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(20px);
            button{
                width: 180px;
                height: 30px;
                border: none;
                color: white;
                padding: 7px;
                background-color: blue;
                cursor: pointer;
            }
        }
    }

    .plano-pro{

        margin-top: 50px;
        background: rgb(175,173,208);
        background: linear-gradient(261deg, rgba(175,173,208,1) 0%, rgba(252,252,252,1) 100%, rgba(0,212,255,1) 100%);
        width: 280px;
        height: 420px;
        border-radius: 5px;
        padding: 20px;

        @media(max-width: 820px){
            margin-top: 30px;
            background: rgb(175,173,208);
            background: linear-gradient(261deg, rgba(175,173,208,1) 0%, rgba(252,252,252,1) 100%, rgba(0,212,255,1) 100%);
            width: 280px;
            height: 420px;
            border-radius: 5px;
            padding: 20px;
        }

        hr{
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .preco-mes-reducao{
            color: red;
        }

        .preco-mes{
            color: green;
            font-weight: bold;
            margin-top: 10px;
        }

        .div-descricao-plano{
            display: flex;
            flex-direction: column;
            font-size: 13px;
                       
        }

        .economia{
            font-size: 11px;
            margin-top: 10px;
        }

        .div-button-adesao{
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(20px);
            button{
                width: 180px;
                height: 30px;
                border: none;
                color: white;
                padding: 7px;
                background-color: blue;
                cursor: pointer;
            }
        }

    }
`

export const Footer = styled.footer`
    background: rgb(0,0,0);
    background: linear-gradient(172deg, rgba(0,0,0,1) 0%, rgba(87,87,87,1) 100%, rgba(252,252,252,1) 100%);
    display: flex;
    flex-direction: column;
    

    .container-footer{
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;

        @media(max-width: 820px){
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .footer-column-infor-column{
            display: flex;
            align-items: baseline;
            flex-direction: column;
            p{ 
                display: flex;
                align-items: center;
                gap: 5px;
                color: white;
                margin-top: 5px;
            }
            h2{
                gap: 5px;
                color: white;
                margin-top: 5px;
            }
        }
        
        .footer-column-social-column{
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 20px;
            p{
                color: white;
            }
        }

        .footer-column-logo-column{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px;
        }

    }

    .footer-column-credits-column{
      
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        p{
            color: white;
            font-size: 12px;
        }
    }

`



