import {Container} from './styleLoading'

function Loading(){
    return(
        <Container >
            <div className='loader'>

            </div>
            <p style={{fontSize: '10px', color: 'black', marginTop: '5px'}}>Carregando...</p>
        </Container>
    )
}

export default Loading