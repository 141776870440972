import moment from "moment";

export default {
    
    hourToMinutes: (hourMinute) => {  //função para transformar hora em minutos
        const hourMinutesr = hourMinute.toString()
        const [hour, minutes] = hourMinutesr.split(':');  //split para seprar a string pelos dois pontos :, onde o index 01 vai ser hour e o 2 minutos 
        return parseInt ( parseInt(hour) * 60 + parseInt(minutes)); // converter em inteiro a hora, multiplica pelos minutos, mais os minutos
   
       }, //fim


    precoServico: (inforPreco) =>{
     const preco = ` R$${inforPreco}`
     return preco
    },




    minutesBlock: ((start, end)=> {  //função para transformar hora em minutos

       //Duração total em minutos
       const Houras = (parseInt(moment(end).format('HH')) - parseInt(moment(start).format('HH')))* 60
       const Minutes = parseInt(moment(end).format('mm')) - parseInt(moment(start).format('mm'))

        return (Houras + Minutes)
   
    }), //fim


    sliceMinutes: (start, end, duration) =>{  // start hora inicial do serviço, hora final do serviço e a duração do serviço
        
        
        const slices = []   //partes que quero cortar  [1:30, 2:00, 3:00]
        let count = 0  

        start = moment(start);      // start =  1:30 = 90
        end = moment(end);          //end = 1:30 + 1:30 = 180
        
        while ( end > start){

            slices.push(start.format('HH:mm'))

            start = start.add(duration, 'minutes')
            count++

        }
        
        return slices
    }, //fim

    sliceMinutesSubtrair: (start, end, duration) =>{  // start hora inicial do serviço, hora final do serviço e a duração do serviço
     
        
        const slices = []   //partes que quero cortar  [1:30, 2:00, 3:00]
        let count = 0  

        start = moment(start)      // start =  1:30 = 90
        end = moment(end);          //end = 1:30 + 1:30 = 180

       
        while ( start > end){

            slices.push(start.format('HH:mm'))
            start = start.subtract(duration, 'minutes')
            count++

        }
        
        return slices
    }, //fim
}