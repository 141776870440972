import styled from "styled-components";


export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;

`

export const ContainerHeader = styled.main`
    width: 100vw;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 375px){
        width: 100vw;
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .divAvatar{
        position: relative;
        top: 130px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media(max-width: 375px){
            top: 100px;
        }

        h2{
            margin-top: 4px;
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
            font-size: 19px;
        }

        .avatar{
            display: flex;
            align-items: center;
            width: 120px;
            height: 120px;
        }
        
    }

`

export const ContainerItens = styled.main`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 60vh;
    flex-direction: column;
    gap: 20px;

    .divLinkBotoes{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        @media(max-width: 375px){
            margin-top: 100px;
        }

        a{
            &:active{
                opacity: 0.4;
            }
            text-decoration: none;
        }


        .ButtonAgendar{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60vw;
            height: 45px;
            color: black;
            font-size: 17px;
            border-radius: 20px;
            border: none;
            color: black;
            color: white;
            font-weight: bold;
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        
            &:active{
                opacity: 0.4;
            }
        }
    }

    .divRedes{

        margin-top: 30px;
        button{
            background-color: transparent;
            width: 50px;
            border: none;

            &:active{
                opacity: 0.4;
            }
        }
    }
`

export const ContainerNav = styled.div`
    width: 100vw;
    height: 10vh;
   

`
